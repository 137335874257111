import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
/* import useMediaQuery from "@mui/material/useMediaQuery"; */
import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import "./App.css";
import CenterForm from "./components/Admin/Centers/CenterForm";
import CenterList from "./components/Admin/Centers/CenterList";
import DoctorForm from "./components/Admin/Doctor/DoctorForm";
import DoctorList from "./components/Admin/Doctor/DoctorList";
import DrrForm from "./components/Admin/DoctorRecognitionRecords/DrrForm";
import DrrList from "./components/Admin/DoctorRecognitionRecords/DrrList";
import PorForm from "./components/Admin/PatientObservationRecords/PorForm";
import PorList from "./components/Admin/PatientObservationRecords/PorList";

import PoList from "./components/Admin/PatientObservations/PoList";

import CmrForm from "./components/Admin/ChemotherapyModalityRecords/CmrForm";
import CmrList from "./components/Admin/ChemotherapyModalityRecords/CmrList";
import CprForm from "./components/Admin/ChemotherapyProtocolRecords/CprForm";
import CprList from "./components/Admin/ChemotherapyProtocolRecords/CprList";
import PatientForm from "./components/Admin/Patients/PatientForm";
import PatientList from "./components/Admin/Patients/PatientList";
import UserForm from "./components/Admin/Users/UserForm";
import UserList from "./components/Admin/Users/UserList";
import ProtectedRouteAdmin from "./components/Auth/ProtectedRouteAdmin";
import ProtectedRouteDoctor from "./components/Auth/ProtectedRouteDoctor";

import CrForm from "./components/Admin/ComorbidityRecords/CrForm";
import CrList from "./components/Admin/ComorbidityRecords/CrList";
import DgrForm from "./components/Admin/DiagnosisGroupRecords/DgrForm";
import DgrList from "./components/Admin/DiagnosisGroupRecords/DgrList";
import Doctor from "./pages/Doctor";
import AdminLogin from "./pages/Login/AdminLogin";
import DoctorLogin from "./pages/Login/DoctorLogin";
import Patient from "./pages/Patient";
import PatientLayout from "./PatientLayout";
import DrrListDoctor from "./components/Doctor/DoctorRecognitionRecords/DrrList";
import DrrFormDoctor from "./components/Doctor/DoctorRecognitionRecords/DrrForm";
import PorListDoctor from "./components/Doctor/PatientObservationRecords/PorList";
import PorFormDoctor from "./components/Doctor/PatientObservationRecords/PorForm";
import PatientListDoctor from "./components/Doctor/Patients/PatientList";
import PatientFormDoctor from "./components/Doctor/Patients/PatientForm";
import PoListDoctor from "./components/Doctor/PatientObservations/PoList";

import AppList from "./components/Doctor/Appointments/AppList";
import AppForm from "./components/Doctor/Appointments/AppForm";
import HrList from "./components/Admin/HistopathologyRecords/HrList";
import HrForm from "./components/Admin/HistopathologyRecords/HrForm";
import IrList from "./components/Admin/IhcRecords/IrList";
import IrForm from "./components/Admin/IhcRecords/IrForm";
import RsrList from "./components/Admin/RadiotherapyScheduleRecords/RsrList";
import RsrForm from "./components/Admin/RadiotherapyScheduleRecords/RsrForm";
import ScrList from "./components/Admin/StagingClinicalRecords/ScrList";
import ScrForm from "./components/Admin/StagingClinicalRecords/ScrForm";
import SprList from "./components/Admin/StagingPathologicalRecords/SprList";
import SprForm from "./components/Admin/StagingPathologicalRecords/SprForm";
import SmrList from "./components/Admin/SurgeryModalityRecords/SmrList";
import SmrForm from "./components/Admin/SurgeryModalityRecords/SmrForm";
import DoctorProfile from "./components/Doctor/Profile";
import Charts from "./components/Admin/Charts";
import BevrList from "./components/Admin/BreastExaminationValueRecords/BevrList";
import BevrForm from "./components/Admin/BreastExaminationValueRecords/BevrForm";
import CcmrList from "./components/Admin/ContraceptiveMethodRecords/CcmrList";
import CcmrForm from "./components/Admin/ContraceptiveMethodRecords/CcmrForm";
import SesrList from "./components/Admin/SocioEconomicStatusRecords/SesrList";
import SesrForm from "./components/Admin/SocioEconomicStatusRecords/SesrForm";
import TNMList from "./components/Admin/TNM/TNMList";
import TNMForm from "./components/Admin/TNM/TNMForm";
import CrrForm from "./components/Admin/ClinicalResponse/CrrForm";
import CrrList from "./components/Admin/ClinicalResponse/CrrList";
import PrrList from "./components/Admin/PathologicalResponse/PrrList";
import PrrForm from "./components/Admin/PathologicalResponse/PrrForm";
import RrrList from "./components/Admin/RadiologicalResponse/RrrList";
import RrrForm from "./components/Admin/RadiologicalResponse/RrrForm";
import CancerList from "./components/Admin/CancerMaker/CancerList";
import CancerForm from "./components/Admin/CancerMaker/CancerForm";
import ResponseList from "./components/Admin/ResponseRate/ResponseList";
import ResponseForm from "./components/Admin/ResponseRate/ResponseForm";
import DiagDiseasesGroupForm from "./components/Admin/DiagDiseasesGroup/DiagDiseasesGroupForm";
import DiagDiseasesGroupList from "./components/Admin/DiagDiseasesGroup/DiagDiseasesGroupList";
import DiagDiseasesSubGroupForm from "./components/Admin/DiagDiseasesSubGroup/DiagDiseasesSubGroupForm";
import DiagDiseasesSubGroupList from "./components/Admin/DiagDiseasesSubGroup/DiagDiseasesSubGroupList";
import PoFormLung from "./components/Admin/PatientObservations/Lung/PoFormLung";
import PoFormLungDoctor from "./components/Doctor/PatientObservations/Lung/PoFormLung";
import DiagMetastaticSiteRecordsForm from "./components/Admin/DiagMetastaticSiteRecords/DiagMetastaticSiteRecordsForm";
import DiagMetastaticSiteRecordsList from "./components/Admin/DiagMetastaticSiteRecords/DiagMetastaticSiteRecordsList";
import DiagPrimarySiteRecordsForm from "./components/Admin/DiagPrimarySiteRecords/DiagPrimarySiteRecordsForm";
import DiagPrimarySiteRecordsList from "./components/Admin/DiagPrimarySiteRecords/DiagPrimarySiteRecordsList";
import DiagLaterilityRecordsForm from "./components/Admin/DiagLaterilityRecords/DiagLaterilityRecordsForm";
import DiagLaterilityRecordsList from "./components/Admin/DiagLaterilityRecords/DiagLaterilityRecordsList";
import Details from "./components/Admin/PatientObservations/Details";
import DetailsDoctor from "./components/Doctor/PatientObservations/Details";
import DCalList from "./components/Admin/DiagCalculation/DCalList";
import DCalForm from "./components/Admin/DiagCalculation/DCalForm";
import ResetPassword from "./components/Admin/ResetPassword";
import AdminForget from "./pages/ForgetPassword/AdminForget";
import ResetPasswordDoctor from "./components/Doctor/ResetPassword";
import AdminProfile from "./components/Admin/Profile";
import NotFoundPage from "./NotFound";
import RSIList from "./components/Admin/RadiotherapyScheduleIntentRecords/RSIList";
import RSIForm from "./components/Admin/RadiotherapyScheduleIntentRecords/RSIForm";
import PoFormBreast from "./components/Admin/PatientObservations/Breast/PoFormBreast";
import MprList from "./components/Admin/MolecularPathologyRecords/MprList";
import MprForm from "./components/Admin/MolecularPathologyRecords/MprForm";
import ExonRecordList from "./components/Admin/ExonRecords/ExonRecordList";
import ExonRecordForm from "./components/Admin/ExonRecords/ExonRecordForm";
import CvrList from "./components/Admin/CovidVaccineRecords/CvrList";
import CvrForm from "./components/Admin/CovidVaccineRecords/CvrForm";
import PoFormBreastDoctor from "./components/Doctor/PatientObservations/Breast/PoFormBreast";
import SsrList from "./components/Admin/SurvivalStatusRecords/SsrList";
import SsrForm from "./components/Admin/SurvivalStatusRecords/SsrForm";

{
  /*
  Theme color palette:
  #0A2647
  #144272
  #205295
  #2C74B3

*/
}

function App() {
  // const udpdateObj = (updatedObj) => {
  //   console.log("JDLDSF", updatedObj);
  //   setDataObj((oldObj) => ({
  //     ...oldObj,
  //     ...updatedObj,
  //   }));
  // };

  /* const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  ); */

  const theme = createTheme({
    palette: {
      primary: {
        main: "#144272",
      },
      sidebar: {
        main: "#0A2647",
        contrastText: "rgba(255, 255, 255, 0.87)",
      },
      default: {
        main: "#fff",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      appbar: {
        main: "#fff",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DoctorLogin />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/forget-password" element={<AdminForget />} />
          <Route path="/doctor/forget-password" element={<AdminForget />} />

          <Route
            path="/doctor"
            element={
              <ProtectedRouteDoctor user={true}>
                <PatientLayout />
              </ProtectedRouteDoctor>
            }
          >
            <Route path="me" element={<DoctorProfile />} />
            <Route path="doctor-recognition-records">
              <Route index element={<DrrListDoctor />} />
              <Route path=":id" element={<DrrFormDoctor />} />
              <Route path="add" element={<DrrFormDoctor />} />
            </Route>
            <Route path="patient-obs-records">
              <Route index element={<PorListDoctor />} />
              <Route path=":id" element={<PorFormDoctor />} />
              <Route path="add" element={<PorFormDoctor />} />
            </Route>
            <Route path="patients">
              <Route index element={<PatientListDoctor />} />
              <Route path=":id" element={<PatientFormDoctor />} />
              <Route path="add" element={<PatientFormDoctor />} />
            </Route>
            {/* <Route path="patient-observations">
              <Route index element={<PoListDoctor />} />
              <Route path=":id" element={<PoFormDoctor />} />
              <Route path="add" element={<PoFormDoctor />} />
            </Route> */}
            <Route path="patient-observations">
              <Route index element={<PoListDoctor />} />
              <Route path="details">
                <Route path=":id" element={<DetailsDoctor />} />
              </Route>
              <Route path="breast">
                {/* <Route path=":id" element={<PoFormBreastDoctor />} /> */}
                <Route path="add" element={<PoFormBreastDoctor />} />
              </Route>
              {/* <Route path="lung">
                <Route path=":id" element={<PoFormLungDoctor />} />
                <Route path="add" element={<PoFormLungDoctor />} />
              </Route> */}
            </Route>
            <Route path="appointments">
              <Route index element={<AppList />} />
              <Route path=":id" element={<AppForm />} />
              <Route path="add" element={<AppForm />} />
            </Route>
            <Route path="reset-password" element={<ResetPasswordDoctor />} />
          </Route>
          <Route
            path="/admin"
            element={
              <ProtectedRouteAdmin user={true}>
                <AdminLayout />
              </ProtectedRouteAdmin>
            }
          >
            <Route path="me" element={<AdminProfile />} />
            <Route path="doctor-recognition-records">
              <Route index element={<DrrList />} />
              <Route path=":id" element={<DrrForm />} />
              <Route path="add" element={<DrrForm />} />
            </Route>
            <Route path="patient-obs-records">
              <Route index element={<PorList />} />
              <Route path=":id" element={<PorForm />} />
              <Route path="add" element={<PorForm />} />
            </Route>
            <Route path="doctors">
              <Route index element={<DoctorList />} />
              <Route path=":id" element={<DoctorForm />} />
              <Route path="add" element={<DoctorForm />} />
            </Route>
            <Route path="patient-observations">
              <Route index element={<PoList />} />
              <Route path="details">
                <Route path=":id" element={<Details />} />
              </Route>
              <Route path="breast">
                <Route path=":id" element={<PoFormBreast />} />
                <Route path="add" element={<PoFormBreast />} />
              </Route>
              {/* <Route path="lung">
                <Route path=":id" element={<PoFormLung />} />
                <Route path="add" element={<PoFormLung />} />
              </Route> */}
            </Route>
            <Route path="centers">
              <Route index element={<CenterList />} />
              <Route path=":id" element={<CenterForm />} />
              <Route path="add" element={<CenterForm />} />
            </Route>
            <Route path="staging-calculation-records">
              <Route index element={<TNMList />} />
              <Route path=":id" element={<TNMForm />} />
              <Route path="add" element={<TNMForm />} />
            </Route>
            <Route path="diagnosis-group-calculation-records">
              <Route index element={<DCalList />} />
              <Route path=":id" element={<DCalForm />} />
              <Route path="add" element={<DCalForm />} />
            </Route>
            <Route path="patients">
              <Route index element={<PatientList />} />
              <Route path=":id" element={<PatientForm />} />
              <Route path="add" element={<PatientForm />} />
            </Route>
            <Route path="users">
              <Route index element={<UserList />} />
              <Route path=":id" element={<UserForm />} />
              <Route path="add" element={<UserForm />} />
            </Route>

            <Route path="chemotherapy-modality-records">
              <Route index element={<CmrList />} />
              <Route path=":id" element={<CmrForm />} />
              <Route path="add" element={<CmrForm />} />
            </Route>
            <Route path="chemotherapy-protocol-records">
              <Route index element={<CprList />} />
              <Route path=":id" element={<CprForm />} />
              <Route path="add" element={<CprForm />} />
            </Route>
            <Route path="comorbidity-records">
              <Route index element={<CrList />} />
              <Route path=":id" element={<CrForm />} />
              <Route path="add" element={<CrForm />} />
            </Route>
            <Route path="diagnosis-group-records">
              <Route index element={<DgrList />} />
              <Route path=":id" element={<DgrForm />} />
              <Route path="add" element={<DgrForm />} />
            </Route>
            <Route path="histopathology-records">
              <Route index element={<HrList />} />
              <Route path=":id" element={<HrForm />} />
              <Route path="add" element={<HrForm />} />
            </Route>
            {/* <Route path="ihc-records">
              <Route index element={<IrList />} />
              <Route path=":id" element={<IrForm />} />
              <Route path="add" element={<IrForm />} />
            </Route> */}
            <Route path="covid-vaccine-company-records">
              <Route index element={<CvrList />} />
              <Route path=":id" element={<CvrForm />} />
              <Route path="add" element={<CvrForm />} />
            </Route>
            <Route path="exon-records">
              <Route index element={<ExonRecordList />} />
              <Route path=":id" element={<ExonRecordForm />} />
              <Route path="add" element={<ExonRecordForm />} />
            </Route>
            <Route path="molecular-pathology-records">
              <Route index element={<MprList />} />
              <Route path=":id" element={<MprForm />} />
              <Route path="add" element={<MprForm />} />
            </Route>
            <Route path="radiotherapy-schedule-records">
              <Route index element={<RsrList />} />
              <Route path=":id" element={<RsrForm />} />
              <Route path="add" element={<RsrForm />} />
            </Route>
            <Route path="staging-clinical-records">
              <Route index element={<ScrList />} />
              <Route path=":id" element={<ScrForm />} />
              <Route path="add" element={<ScrForm />} />
            </Route>
            <Route path="staging-pathological-records">
              <Route index element={<SprList />} />
              <Route path=":id" element={<SprForm />} />
              <Route path="add" element={<SprForm />} />
            </Route>
            <Route path="surgery-modality-records">
              <Route index element={<SmrList />} />
              <Route path=":id" element={<SmrForm />} />
              <Route path="add" element={<SmrForm />} />
            </Route>
            <Route path="survival-status-records">
              <Route index element={<SsrList />} />
              <Route path=":id" element={<SsrForm />} />
              <Route path="add" element={<SsrForm />} />
            </Route>
            <Route path="response-rate-calculation-records">
              <Route index element={<ResponseList />} />
              <Route path=":id" element={<ResponseForm />} />
              <Route path="add" element={<ResponseForm />} />
            </Route>
            <Route path="radiotherapy-schedule-intent-records">
              <Route index element={<RSIList />} />
              <Route path=":id" element={<RSIForm />} />
              <Route path="add" element={<RSIForm />} />
            </Route>
            <Route path="breast-examination-value-records">
              <Route index element={<BevrList />} />
              <Route path=":id" element={<BevrForm />} />
              <Route path="add" element={<BevrForm />} />
            </Route>
            <Route path="contraceptive-method-records">
              <Route index element={<CcmrList />} />
              <Route path=":id" element={<CcmrForm />} />
              <Route path="add" element={<CcmrForm />} />
            </Route>
            <Route path="socio-economic-status-records">
              <Route index element={<SesrList />} />
              <Route path=":id" element={<SesrForm />} />
              <Route path="add" element={<SesrForm />} />
            </Route>
            <Route path="clinical-response-rate-records">
              <Route index element={<CrrList />} />
              <Route path=":id" element={<CrrForm />} />
              <Route path="add" element={<CrrForm />} />
            </Route>
            <Route path="pathological-response-rate-records">
              <Route index element={<PrrList />} />
              <Route path=":id" element={<PrrForm />} />
              <Route path="add" element={<PrrForm />} />
            </Route>
            <Route path="radiological-response-rate-records">
              <Route index element={<RrrList />} />
              <Route path=":id" element={<RrrForm />} />
              <Route path="add" element={<RrrForm />} />
            </Route>
            <Route path="cancer-marker-records">
              <Route index element={<CancerList />} />
              <Route path=":id" element={<CancerForm />} />
              <Route path="add" element={<CancerForm />} />
            </Route>
            <Route path="diagnosis-disease-group-records">
              <Route index element={<DiagDiseasesGroupList />} />
              <Route path=":id" element={<DiagDiseasesGroupForm />} />
              <Route path="add" element={<DiagDiseasesGroupForm />} />
            </Route>
            <Route path="diagnosis-disease-subgroup-records">
              <Route index element={<DiagDiseasesSubGroupList />} />
              <Route path=":id" element={<DiagDiseasesSubGroupForm />} />
              <Route path="add" element={<DiagDiseasesSubGroupForm />} />
            </Route>
            <Route path="diagnosis-laterility-records">
              <Route index element={<DiagLaterilityRecordsList />} />
              <Route path=":id" element={<DiagLaterilityRecordsForm />} />
              <Route path="add" element={<DiagLaterilityRecordsForm />} />
            </Route>
            <Route path="diagnosis-metastatic-site-records">
              <Route index element={<DiagMetastaticSiteRecordsList />} />
              <Route path=":id" element={<DiagMetastaticSiteRecordsForm />} />
              <Route path="add" element={<DiagMetastaticSiteRecordsForm />} />
            </Route>
            <Route path="diagnosis-primary-site-records">
              <Route index element={<DiagPrimarySiteRecordsList />} />
              <Route path=":id" element={<DiagPrimarySiteRecordsForm />} />
              <Route path="add" element={<DiagPrimarySiteRecordsForm />} />
            </Route>
            <Route path="charts" element={<Charts />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
