import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { FeTextField, FeAutocomplete } from "../../../FormElements";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import doctorAPI from "../../../../utils/doctorAPI";
import { isEmpty } from "lodash";
import { updateObjectValue } from "../../../../utils/helper";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DiagnosisDiseases = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    diagnosis_disease_group: "",
    diagnosis_subgroup: "",
    diagnosis_primary_site: "",
    diagnosis_metastatic_sites: [],
    laterality: "",
  });

  const [groupList, setGroupList] = useState([]);
  const [subgroupList, setSubGroupList] = useState([]);
  const [latList, setLatList] = useState([]);
  const [metList, setMetList] = useState([]);
  const [priList, setPriList] = useState([]);
  const [groupValue, setGroupValue] = useState(null);
  const [subGroupValue, setSubGroupValue] = useState(null);
  const [LatValue, setLatGroupValue] = useState(null);
  const [MetValue, setMetGroupValue] = useState([]);
  const [PriGroupValue, setPriGroupValue] = useState(null);

  useEffect(() => {
    if (updatedObj) {
      const u = updateObjectValue(obj, updatedObj);
      setTimeout(() => {
        setObj(u);
        if (u.diagnosis_disease_group) {
          setGroupValue({ name: u.diagnosis_disease_group, id: null });
        }
        if (u.diagnosis_subgroup) {
          setSubGroupValue({ name: u.diagnosis_subgroup, id: null });
        }
        if (u.diagnosis_metastatic_sites) {
          setMetGroupValue(
            u.diagnosis_metastatic_sites.map((item, i) => {
              return { name: item.value };
            })
          );
        }
      }, 100);
    }
  }, [updatedObj]);
  useEffect(() => {
    if (editedObj) {
      setObj(updateObjectValue(obj, editedObj));
      if (editedObj.diagnosis_disease_group) {
        setGroupValue({ name: editedObj.diagnosis_disease_group, id: null });
      }
      if (editedObj.diagnosis_subgroup) {
        setSubGroupValue({ name: editedObj.diagnosis_subgroup, id: null });
      }
      if (editedObj.diagnosis_metastatic_sites) {
        setMetGroupValue(
          editedObj.diagnosis_metastatic_sites.map((item, i) => {
            return { name: item.value };
          })
        );
      }
    }
  }, [editedObj]);
  useEffect(() => {
    getGroupList();
    getLatGroupList();
    getMetGroupList();
    getPriGroupList();

    return () => {};
  }, []);
  useEffect(() => {
    if (!isEmpty(groupValue)) getSubGroupList(groupValue.id);
    return () => {};
  }, [groupValue]);
  const getSubGroupList = async (id) => {
    const { data } = await doctorAPI.get(
      `doctor/diagnosis-disease-subgroup-records-all?diagnosis_disease_group_record_id=${id}`
    );
    setSubGroupList(data.data);
  };
  const getGroupList = async () => {
    const { data } = await doctorAPI.get(
      "doctor/diagnosis-disease-group-records-all"
    );
    setGroupList(data.data);
  };
  const getLatGroupList = async () => {
    const { data } = await doctorAPI.get(
      "doctor/diagnosis-laterility-records-all"
    );
    setLatList(data.data);
  };
  const getMetGroupList = async () => {
    const { data } = await doctorAPI.get(
      "doctor/diagnosis-metastatic-site-records-all"
    );
    setMetList(data.data);
  };
  const getPriGroupList = async () => {
    const { data } = await doctorAPI.get(
      "doctor/diagnosis-primary-site-records-all"
    );
    setPriList(data.data);
  };

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);
  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };

  return (
    <>
      {" "}
      <Grid item xs={6} md={6}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            getOptionLabel={(option) => option.name}
            value={groupValue}
            onChange={(e, v) => {
              if (!v) {
                setGroupValue(v);
                return;
              }
              console.log("VVV", v);
              handleChangeInput("diagnosis_disease_group", v.name);
              setGroupValue(v);
            }}
            label={"diagnosis_disease_group"}
            options={groupList}
            id="Histopathology"
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box mb={2}>
          <FeAutocomplete
            getOptionLabel={(option) => option.name}
            //  disabled={editedObj}
            value={subGroupValue}
            onChange={(e, v) => {
              if (!v) {
                setSubGroupValue(v);
                return;
              }
              console.log("VVV", v);
              handleChangeInput("diagnosis_subgroup", v.name);
              setSubGroupValue(v);
            }}
            label={"diagnosis_subgroup"}
            options={subgroupList}
            id="Histopathology"
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={6} mb={1}>
        {/* <FeTextField
          fullWidth
        //  disabled={editedObj}
          variant="outlined"
          label="diagnosis_primary_site"
          onChange={(evnt) =>
            handleChangeInput("diagnosis_primary_site", evnt.target.value)
          }
        /> */}

        <FeAutocomplete
          //  disabled={editedObj}
          value={obj.diagnosis_primary_site}
          onChange={(e, v) => {
            if (!v) {
              return;
            }
            console.log("VVV", v);
            handleChangeInput("diagnosis_primary_site", v);
            setPriGroupValue(v);
          }}
          label={"diagnosis_primary_site"}
          options={priList.map((o) => o.name)}
          id="Histopathology"
        />
      </Grid>
      <Grid item xs={6} md={6} mb={1}>
        {/* <FeTextField
          fullWidth
        //  disabled={editedObj}
          variant="outlined"
          label="diagnosis_metastatic_sites"
          onChange={(evnt) =>
            handleChangeInput("diagnosis_metastatic_sites", evnt.target.value)
          }
        /> */}
        <FeAutocomplete
          multiple
          label="diagnosis_metastatic_sites"
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          //  disabled={editedObj}
          value={MetValue}
          onChange={(e, v) => {
            handleChangeInput(
              "diagnosis_metastatic_sites",
              v.map((o) => {
                return { value: o.name };
              })
            );
            console.log("VVVV", v);

            setMetGroupValue(v);
          }}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            );
          }}
          options={metList}
          id="Histopathology"
        />
      </Grid>
      <Grid item xs={6} md={6} mb={1}>
        {/* <FeTextField
          fullWidth
        //  disabled={editedObj}
          variant="outlined"
          label="laterality"
          onChange={(evnt) =>
            handleChangeInput("laterality", evnt.target.value)
          }
        /> */}
        <FeAutocomplete
          //  disabled={editedObj}
          value={obj.laterality}
          onChange={(e, v) => {
            if (!v) {
              setLatGroupValue(v);
              return;
            }
            console.log("VVV", v);
            handleChangeInput("laterality", v);
            setLatGroupValue(v);
          }}
          label={"laterality"}
          options={latList.map((o) => o.name)}
          id="Histopathology"
        />
      </Grid>
      <Grid item xs={6} md={6} mb={1}></Grid>
    </>
  );
};

export default DiagnosisDiseases;
