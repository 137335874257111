import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import axios from "axios";
import { transformIHCSData } from "../../../utils/helper";

const Details = () => {
  const [obj, setObj] = useState({});
  const { id } = useParams();
  const [ihcsRows, setIhcsRows] = useState([]);

  useEffect(() => {
    getIhcsRows();
  }, []);
  const getIhcsRows = async () => {
    const { data } = await adminAPi.get("admin/ihc-records-all");
    if (data?.data?.length > 0) {
      const names = transformIHCSData(data?.data);
      setIhcsRows(names);
    }

    // const result = createIHCObject(names);
    // setObj(result);
    // setObj({ihcs:names.map((n)=>({name:n}))});
  };
  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);
  async function getDetails(id) {
    const { data } = await adminAPi.get(
      `admin/patients-with-observation/${id}`
    );
    setObj(data?.data);
  }

  const renderProtocols = (protocols) => {
    if (protocols.length > 0) {
      const protocolSummary = protocols
        .map(
          (protocol, index) =>
            ` ${
              protocol.type === "followed_by" ? "Followed by" : ""
            } ${protocol.chemotherapy_protocol_details
              .map((detail) => detail.value)
              .join(" + ")} ${protocol.cycle_no ?? ""} ${
              protocol.cycle_no ? "Cycle" : ""
            }`
        )
        .join(", ");
      return protocolSummary;
    }
  };

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }
  const downloadPDF = (obj) => {
    console.log("obj", obj);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}patients-with-observation!pdf/${id}`,
        {
          method: "GET",
          // responseType: "blob", //Force to receive data in a Blob Format
        }
      )
      .then((response) => {
        // const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        // const fileURL = URL.createObjectURL(file); //Open the URL on new Window

        // var a = document.createElement("a");
        // a.href = fileURL;

        // a.download = `${obj?.name}_${obj?.patient_observations?.registration_no}.pdf`;

        // a.click();
        // window.URL.revokeObjectURL(fileURL);
        // window
        //   .open(
        //     `${process.env.REACT_APP_BASE_URL}patients-with-observation!pdf/${obj.id}`
        //   )
        //   .focus();
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_BASE_URL}patients-with-observation!pdf/${id}`;
        link.click();

        //
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        {" "}
        <Button variant="outlined" onClick={() => downloadPDF(obj)}>
          Download PDF
        </Button>
      </Box>

      <Box sx={{ border: "1px solid #f1f1f1" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box>
              Patient name:{" "}
              <span style={{ fontWeight: "bold" }}>{obj?.name}</span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Registration No.{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {obj?.patient_observations?.registration_no}
              </span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Center:{" "}
              <span style={{ fontWeight: "bold" }}>
                {obj?.patient_observations?.center?.name}
              </span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Consultant:{" "}
              <span style={{ fontWeight: "bold" }}>
                {obj?.patient_observations?.doctor?.name}
              </span>{" "}
            </Box>
          </Grid>
        </Grid>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12} md={12}>
            <h3>Diagnosis:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Diagnosis Disease Group</TableCell>
                    <TableCell>Diagnosis Subgroup</TableCell>
                    <TableCell>Primary site</TableCell>
                    <TableCell>Metastatic Site</TableCell>
                    <TableCell>Laterality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.diagnosis?.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.diagnosis_disease_group}</TableCell>
                        <TableCell>{row.diagnosis_subgroup}</TableCell>
                        <TableCell>{row.diagnosis_primary_site}</TableCell>
                        <TableCell>
                          {row.diagnosis_metastatic_sites?.length > 0 &&
                            row.diagnosis_metastatic_sites.map((item, i) => (
                              <p
                                style={{
                                  borderBottom:
                                    "1px solid rgba(224, 224, 224, 1)",
                                  paddingBottom: "10px",
                                }}
                                key={i}
                              >
                                {" "}
                                {item.value}
                              </p>
                            ))}
                        </TableCell>
                        {/* <TableCell>
                          {row.diagnosis_metastatic_sites?.length > 0 &&
                            row.diagnosis_metastatic_sites
                              .map((item, i) => item.value)
                              .join(" , ")}
                        </TableCell> */}
                        <TableCell>{row.laterality}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={7}>
            <h3>Histopathology:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Histopathology Report</TableCell>
                    <TableCell>Grade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.histopathology?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>

                          <TableCell>
                            {row.histopathologies?.length > 0 &&
                              row.histopathologies.map((item, i) => (
                                <p
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(224, 224, 224, 1)",
                                    paddingBottom: "10px",
                                  }}
                                  key={i}
                                >
                                  {" "}
                                  {item.detail}
                                </p>
                              ))}
                          </TableCell>
                          {/* {row.histopathologies?.length > 0 &&
                              row.histopathologies
                                .map((item, i) => item.detail)
                                .join(" , ")} */}
                          <TableCell>{row.grade}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <h3>TNM Staging (Clinical) :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>T</TableCell>
                      <TableCell>N</TableCell>
                      <TableCell>M</TableCell>
                      <TableCell>Stage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.patient_observations?.staging_clinicals?.map(
                      (data, index) => (
                        <React.Fragment key={index}>
                          <TableRow key={data.id}>
                            <TableCell>
                              {" "}
                              {data.date &&
                                dayjs(data.date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{data.t}</TableCell>
                            <TableCell>{data.n}</TableCell>
                            <TableCell>{data.m}</TableCell>
                            <TableCell>{data.result}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <h3>TNM Staging (Pathological) :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>T</TableCell>
                      <TableCell>N</TableCell>
                      <TableCell>M</TableCell>
                      <TableCell>Stage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.patient_observations?.staging_pathologicals?.map(
                      (data, index) => (
                        <React.Fragment key={index}>
                          <TableRow key={data.id}>
                            <TableCell>
                              {" "}
                              {data.date &&
                                dayjs(data.date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{data.t}</TableCell>
                            <TableCell>{data.n}</TableCell>
                            <TableCell>{data.m}</TableCell>
                            <TableCell>{data.result}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>IHC :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    {ihcsRows?.length > 0 &&
                      ihcsRows.map((row) => <TableCell>{row.name}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.patient_ihc_observation_details?.map(
                    (row, index) => (
                      <React.Fragment key={index}>
                        <TableRow key={row.id}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>

                          {ihcsRows?.length > 0 &&
                            ihcsRows
                              .map((v) => v.key)
                              .map((col) => (
                                <TableCell>{row?.ihc?.[col]}</TableCell>
                              ))}
                        </TableRow>
                      </React.Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3>Pathological Staging - Details :</h3>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>

                    <TableCell>lvsi</TableCell>

                    <TableCell>pni</TableCell>

                    <TableCell>margin</TableCell>

                    <TableCell>ki67</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {obj?.patient_observations?.patient_ihc_observation_details?.map(
                    (data, index) => (
                      <TableRow key={data.id}>
                        <TableCell>
                          {" "}
                          {data.date && dayjs(data.date).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell>
                          {data?.staging_pathological_details?.lvsi}
                        </TableCell>

                        <TableCell>
                          {data?.staging_pathological_details?.pni}
                        </TableCell>

                        <TableCell>
                          {data?.staging_pathological_details?.margin}
                        </TableCell>

                        <TableCell>
                          {data?.staging_pathological_details?.ki67}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3>Molecular Pathology :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Method</TableCell>
                    <TableCell>Gene</TableCell>
                    <TableCell>Exon</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Detail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.molecular_pathologies?.map(
                    (row, index) => (
                      <React.Fragment key={index}>
                        {row.map((data) => (
                          <TableRow key={data.id}>
                            <TableCell>
                              {" "}
                              {data.date &&
                                dayjs(data.date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{data.method}</TableCell>
                            <TableCell>{data.gene}</TableCell>
                            <TableCell>{data.exon}</TableCell>
                            <TableCell>{data.status}</TableCell>
                            <TableCell>{data.detail}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={12}>
            <h3>Chemo Details :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Starting Date</TableCell>
                    <TableCell>Modality</TableCell>
                    <TableCell>LOT</TableCell>
                    <TableCell>Cycle No.</TableCell>
                    <TableCell>Protocol</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.chemotherapy?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>

                          {/* <TableCell>
                            {row.chemotherapy_modalities?.length > 0 &&
                              row.chemotherapy_modalities
                                .map((item, i) => item.detail)
                                .join(" , ")}
                          </TableCell> */}
                          <TableCell>
                            {row.chemotherapy_modalities?.length > 0 &&
                              row.chemotherapy_modalities.map((item, i) => (
                                <p
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(224, 224, 224, 1)",
                                    paddingBottom: "10px",
                                  }}
                                  key={i}
                                >
                                  {" "}
                                  {item.detail}
                                </p>
                              ))}
                          </TableCell>
                          <TableCell>{row.line_of_treatment}</TableCell>
                          {/* <TableCell>
                            {" "}
                            {row.chemotherapy_protocols?.length > 0 &&
                              row.chemotherapy_protocols
                                .map((item, i) => item.cycle_no)
                                .join(" , ")}
                          </TableCell> */}
                          <TableCell>
                            {row.chemotherapy_protocols?.length > 0 &&
                              row.chemotherapy_protocols.map((item, i) => (
                                <p
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(224, 224, 224, 1)",
                                    paddingBottom: "10px",
                                  }}
                                  key={i}
                                >
                                  {" "}
                                  {item.cycle_no}
                                </p>
                              ))}
                          </TableCell>
                          <TableCell>
                            {renderProtocols(row.chemotherapy_protocols)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                  {/* {obj?.patient_observations?.chemotherapy?.map(
                    (row, index) => (
                      <React.Fragment key={index}>
                        {row.map((data) => (
                          <TableRow key={data.id}>
                            <TableCell>
                              {" "}
                              {data.date &&
                                dayjs(data.date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{data.cycle_no}</TableCell>
                            <TableCell>{renderProtocols(row)}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    )
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3>Surgery :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Surgery Date</TableCell>
                    <TableCell>Modality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.surgeries?.map((data, index) => (
                    <React.Fragment key={index}>
                      <TableRow key={data.id}>
                        <TableCell>
                          {" "}
                          {data.surgery_date &&
                            dayjs(data.surgery_date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{data.modality}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Radiotherapy Schedules :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Intent</TableCell>
                    <TableCell>Modality</TableCell>
                    <TableCell>Site</TableCell>
                    <TableCell>Fraction Dose</TableCell>
                    <TableCell>Fraction number / Dose</TableCell>
                    <TableCell>Total Dose in cGy</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.radiotherapy_schedules?.map(
                    (row, index) => (
                      <React.Fragment key={index}>
                        {row.map((data) => (
                          <TableRow key={data.id}>
                            <TableCell>
                              {" "}
                              {data.start_date &&
                                dayjs(data.start_date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {data.end_date &&
                                dayjs(data.end_date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{data.intent}</TableCell>
                            <TableCell>
                              {data.radiotherapy_schedule_modalities?.length >
                                0 &&
                                data.radiotherapy_schedule_modalities
                                  .map((item, i) => item.value)
                                  .join(" , ")}
                            </TableCell>
                            <TableCell>
                              {data.radiotherapy_schedule_sites?.length > 0 &&
                                data.radiotherapy_schedule_sites
                                  .map((item, i) => item.value)
                                  .join(" , ")}
                            </TableCell>
                            <TableCell>{data.fraction}</TableCell>
                            <TableCell>{data.fraction_number}</TableCell>
                            <TableCell>{data.total_dose}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Clinical Response Rate:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Target lesion</TableCell>
                    <TableCell>Non target lesion</TableCell>
                    <TableCell>New Lasion</TableCell>
                    <TableCell>Response Rate</TableCell>
                    <TableCell>Progression Site</TableCell>
                    <TableCell>Method of Estimation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.clinical_response_rate?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {row.clinical_response_rate_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.clinical_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.clinical_response_rate_new_lasion}
                          </TableCell>
                          <TableCell>
                            {row.clinical_response_rate_result}
                          </TableCell>

                          <TableCell>
                            {row.clinical_progression_sites?.length > 0 &&
                              row.clinical_progression_sites.join(" , ")}
                          </TableCell>
                          <TableCell>
                            {row.clinical_method_of_estimation}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Pathological Response Rate:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Target lesion</TableCell>
                    <TableCell>Non target lesion</TableCell>
                    <TableCell>New Lasion</TableCell>
                    <TableCell>Response Rate</TableCell>
                    <TableCell>Progression Site</TableCell>
                    <TableCell>Method of Estimation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.pathological_response_rate?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {row.pathological_response_rate_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.pathological_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.pathological_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.pathological_response_rate_result}
                          </TableCell>

                          <TableCell>
                            {row.pathological_progression_sites?.length > 0 &&
                              row.pathological_progression_sites.join(" , ")}
                          </TableCell>
                          <TableCell>
                            {row.pathological_method_of_estimation}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Radiological Response Rate:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Target lesion</TableCell>
                    <TableCell>Non target lesion</TableCell>
                    <TableCell>New Lasion</TableCell>
                    <TableCell>Response Rate</TableCell>
                    <TableCell>Progression Site</TableCell>
                    <TableCell>Method of Estimation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.radiological_response_rate?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {row.radiological_response_rate_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.radiological_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.radiological_response_rate_new_lasion}
                          </TableCell>
                          <TableCell>
                            {row.radiological_response_rate_result}
                          </TableCell>

                          <TableCell>
                            {row.radiological_progression_sites?.length > 0 &&
                              row.radiological_progression_sites.join(" , ")}
                          </TableCell>
                          <TableCell>
                            {row.radiological_method_of_estimation}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <h3>Disease Progression Status :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Disease Progression Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.disease_progression_status?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {row.disease_progression_status}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Survival Status :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Survival Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.survival_status?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{row.survival_status}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Details;
