import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  setRef,
  TableContainer,
  TableHead,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import Checkbox from "@mui/material/Checkbox";
import { FeTextField, FeAutocomplete } from "../../../FormElements";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import adminAPi from "../../../../utils/adminAPi";
import { isEmpty } from "lodash";
import { transformIHCSData, updateObjectValue } from "../../../../utils/helper";

const radioOptions = [
  "positive",
  "negative",
  "postive_3+",
  "equvocal_2+",
  "negative_1+",
  "not_done",
];
const IHCForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    ihcs: [
      {
        er: null,
        pr: null,
        her2: null,
        fish: null,
      },
    ],
  });
  const [rows, setRows] = useState([]);
  useEffect(() => {
    getRows();

    return () => {};
  }, []);

  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(obj, editedObj);
    }
    console.log("E U a", e, u);
    if (u) {
      setTimeout(() => {
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      if (e.ihcs.length === 0) {
        setObj({
          ihcs: [
            {
              er: null,
              pr: null,
              her2: null,
              fish: null,
            },
          ],
        });
        return;
      }
      setTimeout(() => {
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);
  // useEffect(() => {
  //   if (updatedObj) {
  //     const u = updateObjectValue(obj, updatedObj);
  //     setTimeout(() => {
  //       setObj(u);
  //     }, 100);
  //   }
  // }, [updatedObj]);
  // useEffect(() => {
  //   if (editedObj) {
  //     setObj(Object.assign({}, obj, editedObj));
  //   }
  // }, [editedObj]);
  const getRows = async () => {
    const { data } = await adminAPi.get("admin/ihc-records-all");
    setRows(data.data);
  };
  const handleChangeRadioBox = (e) => {
    const { name, value } = e.target;
    const list = [...obj.ihcs];
    list[0][name] = value;
    setObj({ ihcs: list });
  };

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box mb={2}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.23)",
              maxHeight: 360,
            }}
          >
            <Box py={1.5} pl={2}>
              <FormLabel>IHC</FormLabel>
            </Box>
            <Table
              size="small"
              stickyHeader
              sx={{
                borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                "& tr": {
                  "&:last-child": {
                    "& td": {
                      borderBottom: 0,
                    },
                  },
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Undo</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Positive</TableCell>
                  <TableCell align="center">Negative</TableCell>
                  <TableCell align="center">Positive 3+</TableCell>
                  <TableCell align="center">Equvocal 2+</TableCell>
                  <TableCell align="center">Negative 1+</TableCell>
                  <TableCell align="center">Not Done</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 &&
                  transformIHCSData(rows).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <IconButton
                          type="button"
                          onClick={() => {
                            const list = [...obj.ihcs];
                            list[0][row.key] = null;
                            setObj({ ihcs: list });
                          }}
                          color="primary"
                          aria-label="add to shopping cart"
                        >
                          <UndoIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      {radioOptions.map((item, i) => (
                        <TableCell key={i} align="center">
                          {obj.ihcs[0] && (
                            <Radio
                              checked={obj.ihcs[0][row.key] === item}
                              //  disabled={editedObj}
                              onChange={(e) => handleChangeRadioBox(e)}
                              value={item}
                              name={row.key}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </>
  );
};

export default IHCForm;
