import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import { FeAutocomplete } from "../../FormElements";
import FeTextField from "../../FormElements/FeTextField";

const records = [
  {
    value: "gene",
    label: "Gene Name",
  },
  {
    value: "specimen",
    label: "Specimen",
  },
  {
    value: "method",
    label: "Method",
  },
  {
    value: "exon",
    label: "Exon",
  },
  {
    value: "status",
    label: "Status",
  },
];
const MprForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [formValue, setFormValue] = useState({
    group: "gene",
    name: "",
  });
  const [groupName, setGroupName] = useState(records[0]);

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    const { data } = await adminAPi.get(
      `admin/molecular-pathology-records/${id}`
    );
    setFormValue(data.data);
    setGroupName({
      value: data.data.group,
      label: records.find((r) => r.value === data.data.group).label,
    });
  }

  const handleField = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const { data } = await adminAPi.put(
          `admin/molecular-pathology-records/${id}`,
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        const { data } = await adminAPi.post(
          "admin/molecular-pathology-records",
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <FeAutocomplete
              name="group"
              id="group"
              label="Group"
              value={groupName}
              getOptionLabel={(option) => option.label}
              options={records}
              onChange={(event, v) => {
                setGroupName(v);
                const name = "group";
                if (v) {
                  const value = v.value;
                  let e = { target: { name, value } };
                  handleField(e);
                } else {
                  const value = "";
                  let e = { target: { name, value } };
                  handleField(e);
                }
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <FeTextField
              name="name"
              id="name"
              label="Name"
              value={formValue.name}
              onChange={handleField}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
export default MprForm;
