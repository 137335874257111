import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeAutocomplete } from "../../../FormElements";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Checkbox from "@mui/material/Checkbox";
import { updateObjectValue } from "../../../../utils/helper";

import { isEmpty } from "lodash";
import doctorAPI from "../../../../utils/doctorAPI";
import FeTextArea from "../../../FormElements/FeTextArea";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ChemoDetails = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    chemo_detail: "",
  });

  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  useEffect(() => {
    if (editedObj) {
      setObj(Object.assign({}, obj, editedObj));
    }
  }, [editedObj]);

  useEffect(() => {
    if (updatedObj) {
      const u = updateObjectValue(obj, updatedObj);
      setTimeout(() => {
        setObj(u);
      }, 100);
    }
  }, [updatedObj]);
  return (
    <>
      {" "}
      <Grid item xs={6} md={6}>
        <Box mb={1}>
          <FeTextArea
            //  disabled={editedObj}
            label="Chemo Details (Chronology)"
            onBlur={(e) => handleChangeInput("chemo_detail", e.target.value)}
            defaultValue={obj.chemo_detail}
          />
        </Box>
      </Grid>
    </>
  );
};

export default ChemoDetails;
