import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeAutocomplete } from "../../../FormElements";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Checkbox from "@mui/material/Checkbox";
import { updateObjectValue } from "../../../../utils/helper";

import { isEmpty } from "lodash";
import adminAPi from "../../../../utils/adminAPi";
import FeTextArea from "../../../FormElements/FeTextArea";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ChemoModDetails = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    chemotherapy_modalities: [
      {
        detail: "",
      },
    ],
    chemo_detail: "",
  });

  const [chemoModList, setChemoModList] = useState([]);
  const [modValue, setModValue] = useState([]);

  useEffect(() => {
    getHistopathologies();

    return () => {};
  }, []);

  const getHistopathologies = async () => {
    const { data } = await adminAPi.get(
      "admin/chemotherapy-modality-records-all"
    );
    setChemoModList(data.data);
  };

  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  useEffect(() => {
    if (editedObj) {
      setObj(Object.assign({}, obj, editedObj));
      if (editedObj?.chemotherapy_modalities) {
        setModValue(editedObj?.chemotherapy_modalities?.map((o) => o.detail));
      }
    }
  }, [editedObj]);
  useEffect(() => {
    if (updatedObj) {
      console.log("UPDDDDD", updatedObj);
      const u = updateObjectValue(obj, updatedObj);
      console.log("UUUUU", u);
      if (updatedObj.chemotherapy_modalities) {
        setTimeout(() => {
          setModValue(
            updatedObj?.chemotherapy_modalities?.map((o) => o.detail)
          );
          setObj(u);
        }, 100);
      }
    }
  }, [updatedObj]);
  return (
    <>
      {" "}
      <Grid item xs={6} md={6}>
        <Box mb={1}>
          <label className="form-label">Treatment Modality</label>
          <FeAutocomplete
            multiple
            disableCloseOnSelect
            //  disabled={editedObj}
            value={modValue}
            onChange={(e, v) => {
              handleChangeInput(
                "chemotherapy_modalities",
                v.map((o) => {
                  return { detail: o };
                })
              );
              console.log("VVVV", v);
              setModValue(v);
            }}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              );
            }}
            options={chemoModList.map((o) => o.name)}
            id="Histopathology"
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        {/* <Box mb={1}>
          <FeTextArea
          //  disabled={editedObj}
            label="Treatment Details (Chronology)"
            onBlur={(e) => handleChangeInput("chemo_detail", e.target.value)}
            defaultValue={obj.chemo_detail}
          />
        </Box> */}
      </Grid>
    </>
  );
};

export default ChemoModDetails;
